import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/Home";
import ErrorPage from "./pages/Error";
import RootLayout from "./pages/Root";
import DevicesRootLayout from "./pages/DevicesRoot";
import DevicesPage from "./pages/Devices";
import DeviceDetailPage from "./pages/DeviceDetail";
import { loader as devicesLoader } from "./pages/Devices"
import { loader as deviceDetailLoader } from "./pages/DeviceDetail"
import { checkAuthLoader, tokenLoader } from "./util/auth";
import UserService from "./services/UserService";

UserService.initKeycloak()

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;
  const token = window.accessToken ? window.accessToken : localStorage.getItem("token")
  config.headers["Authorization"] = "Bearer " + token
  const response = await originalFetch(resource, config);

  // response interceptor here
  return response;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    loader: tokenLoader,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "devices",
        element: <DevicesRootLayout />,
        children: [
          {
            index: true,
            element: <DevicesPage />,
            loader: devicesLoader,
          },
          {
            path: ":deviceId",
            id: "device-detail",
            loader: deviceDetailLoader,
            children: [
              {
                index: true,
                element: <DeviceDetailPage />,
                //action: deleteEventAction,
              },
            ],
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
