import LoginForm from "../components/LoginForm";
import {
  Navigate
} from "react-router-dom";
import MenuAppBar from "../navbar/MenuAppBar";

function HomePage() {
  return (
    //<Navigate replace to="/devices" />
     <>
      <MenuAppBar />
      <center><h1>Welcome to RIRRIGO</h1></center>
    </>
  );
}

export default HomePage;
