import { Outlet } from 'react-router-dom';
import MenuAppBar from '../navbar/MenuAppBar';

function DevicesRootLayout() {
  return (
    <>
      <MenuAppBar />
      <Outlet />
    </>
  );
}

export default DevicesRootLayout;
