import { Link, useRouteLoaderData, json } from "react-router-dom";
import * as React from "react";
import { useState } from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


function DeviceItem({ device }) {
    const [turnedOn, setTurnedOn] = useState(() => {
        console.log(device)
        if (device.settings == null) {
            return false;
        }
        else if (device.settings != null && !device.settings.hasOwnProperty('turned_on')) {
            return false
        }
        return device.settings.turned_on

    })
    const token = useRouteLoaderData("root");
    console.log(device)

    const handleSwitchWaterClick = async (turnedOn) => {
        console.log(device.settings)
        let settings = {}
        if (device.settings == null) {
            settings = {
                work_mode: 'MANUAL',
                turned_on: turnedOn,
                device: device.id
            }
        }
        else if (device.settings != null && !device.settings.hasOwnProperty('turned_on')) {
            settings = {
                ...device.settings,
                turned_on: turnedOn
            }
        }
        else {
            device.settings.turned_on = turnedOn
            settings = device.settings
        }
        delete settings._id
        delete settings.createdAt
        delete settings.updatedAt
        console.log(settings)
        const response = await fetch(
          "/api/settings/manual",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(settings),
          }
        );

        console.log(response)
        if (!response.ok) {
            // return { isError: true, message: 'Could not fetch events.' };
            // throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
            //   status: 500,
            // });
            throw json(
            { message: "Could not fetch events." },
            {
                status: 500,
            }
            );
        } else {
            const resData = await response.json();
            console.log(resData.data)
            setTurnedOn(turnedOn)
            return resData.data;
        }
    };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box sx={{ width: "100%", maxWidth: 500 }}>
          <Typography gutterBottom variant="h5" component="div">
            {device.name}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            {device.location}
          </Typography>
          {device.status === true ? (
            <Typography variant="body2" color="green">
              ONLINE
            </Typography>
          ) : (
            <Typography variant="body2" color="red">
              OFFLINE
            </Typography>
          )}
          {turnedOn === false ? (
            <>
              <Typography variant="body2" color="red">
                Voda iskljucena
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleSwitchWaterClick(true)}
              >
                Ukljuci
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body2" color="green">
                Voda ukljucena
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleSwitchWaterClick(false)}
              >
                Iskljuci
              </Button>
            </>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default DeviceItem;
