// import { useLoaderData } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';

import classes from './DevicesList.module.css';
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardHeader } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

function DevicesList(devices) {
  const navigate = useNavigate();
  console.log(devices)

  const handleClick = (id) => {
    console.log(id);
    return navigate(id);
  }
  // const events = useLoaderData();
  /*const devices = [
    {
      id: "123456",
      name: "ESP Vrt",
      location: "Mladice odvojak 1",
      status: "ONLINE",
    },
    {
      id: "1234567",
      name: "ESP Vrt",
      location: "Mladice odvojak 1",
      status: "ONLINE",
    },
    {
      id: "1234568",
      name: "ESP Vrt",
      location: "Mladice odvojak 1",
      status: "ONLINE",
    },
    {
      id: "1234569",
      name: "ESP Vrt",
      location: "Mladice odvojak 1",
      status: "OFFLINE",
    },
  ];*/

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {devices.devices.map((device) => (
          <Grid xs={4} key={device.id}>
            <Card>
              <CardActionArea onClick={() => handleClick(device.id)}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {device.name}
                  </Typography>
                  <Typography gutterBottom variant="body1" component="div">
                    {device.location}
                  </Typography>
                  {device.status === true ?
                    <Typography variant="body2" color="green">
                      ONLINE
                    </Typography> :
                      <Typography variant="body2" color="red">
                        OFFLINE
                    </Typography>
                  }
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default DevicesList;
