import Keycloak from "keycloak-js";
import env from "react-dotenv";

let initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
  onLoad: "login-required", // check-sso | login-required
  KeycloakResponseType: "code",
};

const _kc = new Keycloak(initOptions);

const initKeycloak = () => {
  _kc
    .init({
      onLoad: initOptions.onLoad,
      KeycloakResponseType: "code",
      checkLoginIframe: false,
      pkceMethod: "S256",
    }).then(
      (auth) => {
        if (!auth) {
          window.location.reload();
        } else {
          console.info("Authenticated");
          console.log("auth", auth);
          window.accessToken = _kc.token;
          localStorage.setItem("token", _kc.token);
          console.log("Keycloak", _kc);
          _kc.onTokenExpired = () => {
            console.log("token expired");
          };
        }
      },
      () => {
        console.error("Authenticated Failed");
      }
    );
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (secs) =>
  _kc.updateToken(secs).then(console.log("Token refreshed")).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
};

export default UserService;
