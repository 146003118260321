import DevicesList from "../components/DevicesList"
import { Suspense } from "react";
import { useLoaderData, json, defer, Await } from "react-router-dom";

function DevicesPage() {
    const { devices } = useLoaderData();
    return (
        <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
            <Await resolve={devices}>
                {(loadedDevices) => <DevicesList devices={loadedDevices} /> }
            </Await>
        </Suspense>
    )
}

export default DevicesPage

async function loadDevices() {
    const response = await fetch("/api/devices/by/user",
    {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        }
    });

    console.log(response)
  if (!response.ok) {
    // return { isError: true, message: 'Could not fetch events.' };
    // throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
    //   status: 500,
    // });
    throw json(
      { message: "Could not fetch events." },
      {
        status: 500,
      }
    );
  } else {
      const resData = await response.json();
      console.log(resData.data)
    return resData.data;
  }
}

export function loader() {
  return defer({
    devices: loadDevices(),
  });
}